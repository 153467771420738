import { UserPrompt } from 'src/modules/optins/models/types';

export async function talkToAIAPI({
  prompt,
  code,
  temperature = 1,
}: {
  prompt: UserPrompt;
  code: string;
  temperature?: number;
}) {
  const requestBody = {
    code,
    prompt: prompt.prompt,
    imgURL: prompt.imageURL ?? '',
    temperature,
  };

  const res = await fetch('/api/ai-optins', {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  });

  const data = await res.json();

  return data;
}
