import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useGlobalState } from 'src/hooks/use-global-state';
import { TrialBaseModal } from './TrialBaseModal';

const trial79 = [
  {
    label: 'email_features',
    type: 'header',
  },
  {
    label: 'assisted_migration',
    free: 'disabled',
    paidPlan: 'enabled',
  },
  {
    label: 'all_campaign_features',
    free: 'disabled',
    paidPlan: 'enabled',
    tooltip: 'campaign_automation_tooltip',
  },
  {
    label: 'webpush_features',
    type: 'header',
  },
  {
    type: 'label',
    label: 'number_of_webpush',
    free: '500/month',
    paidPlan: '25k-∞/month',
  },
  {
    label: 'automation',
    paidPlan: 'enabled',
  },
  {
    label: 'acr',
    paidPlan: 'enabled',
  },
  {
    label: 'shipping',
    paidPlan: 'enabled',
  },
  {
    label: 'ba',
    paidPlan: 'enabled',
  },
  {
    label: 'custom_automation',
    paidPlan: 'enabled',
  },
  {
    label: 'campaigns',
    type: 'header',
  },
  {
    label: 'flash_sale',
    paidPlan: 'enabled',
  },
  {
    label: 'send_and_schedule',
    paidPlan: 'enabled',
  },
  {
    label: 'segmentation',
    paidPlan: 'enabled',
  },
  {
    label: 'smart_delivery',
    paidPlan: 'enabled',
  },
  {
    label: 'brand_logo',
    paidPlan: 'enabled',
  },
  {
    label: 'support_for_all_channels',
    type: 'header',
  },
  { label: 'priority_support', free: 'disabled', paidPlan: 'enabled' },
  {
    label: 'subscriber_import_and_export',
    free: 'disabled',
    paidPlan: 'enabled',
  },
];
const trial19 = [
  {
    label: 'email_features',
    type: 'header',
  },
  {
    label: 'assisted_migration',
    free: 'disabled',
    paidPlan: 'enabled',
  },
  {
    label: 'all_campaign_features',
    free: 'disabled',
    paidPlan: 'enabled',
    tooltip: 'campaign_automation_tooltip',
  },
  {
    label: 'webpush_features',
    type: 'header',
  },
  {
    type: 'label',
    label: 'number_of_webpush',
    free: '500/month',
    paidPlan: '10k-30k/month',
  },
  {
    label: 'automation',
    free: 'disabled',
    paidPlan: 'enabled',
  },
  {
    label: 'welcome',
    free: 'disabled',
    paidPlan: 'enabled',
  },
  {
    label: 'back_in_stock',
    free: 'disabled',
    paidPlan: 'enabled',
  },
  {
    label: 'price_drop',
    paidPlan: 'enabled',
  },
  {
    label: 'acr',
    paidPlan: 'enabled',
  },
  {
    label: 'shipping',
    paidPlan: 'enabled',
  },
  {
    label: 'brand_logo',
    paidPlan: 'enabled',
  },
  {
    label: 'campaigns',
    free: 'disabled',
    paidPlan: 'enabled',
  },
  {
    label: 'send_and_schedule',
    free: 'disabled',
    paidPlan: 'enabled',
  },
  {
    label: 'support',
    free: 'disabled',
    paidPlan: 'enabled',
  },
  {
    label: 'priority_support',
    free: 'disabled',
    paidPlan: 'enabled',
  },
  {
    label: 'subscriber_import_and_export',
    free: 'disabled',
    paidPlan: 'enabled',
  },
];

const trial38 = [
  {
    label: 'email_features',
    type: 'header',
  },
  {
    label: 'assisted_migration',
    free: 'disabled',
    paidPlan: 'enabled',
  },
  {
    label: 'all_campaign_features',
    free: 'disabled',
    paidPlan: 'enabled',
    tooltip: 'campaign_automation_tooltip',
  },
  {
    label: 'webpush_features',
    type: 'header',
  },
  {
    type: 'label',
    label: 'number_of_webpush',
    free: '500/month',
    paidPlan: '10k-30k/month',
  },
  {
    label: 'automation',
    free: 'disabled',
    paidPlan: 'enabled',
  },
  {
    label: 'welcome',
    free: 'disabled',
    paidPlan: 'enabled',
  },
  {
    label: 'back_in_stock',
    free: 'disabled',
    paidPlan: 'enabled',
  },
  {
    label: 'price_drop',
    paidPlan: 'enabled',
  },
  {
    label: 'acr',
    paidPlan: 'enabled',
  },
  {
    label: 'shipping',
    paidPlan: 'enabled',
  },
  {
    label: 'brand_logo',
    paidPlan: 'enabled',
  },
  { label: 'campaigns', free: 'disabled', paidPlan: 'enabled' },
  {
    label: 'send_and_schedule',
    free: 'disabled',
    paidPlan: 'enabled',
  },
  {
    label: 'support',
    free: 'disabled',
    paidPlan: 'enabled',
  },
  {
    label: 'priority_support',
    free: 'disabled',
    paidPlan: 'enabled',
  },
  {
    label: 'subscriber_import_and_export',
    free: 'disabled',
    paidPlan: 'enabled',
  },
];

export const TrialModal = () => {
  const { t } = useTranslation('trial');
  const {
    state: {
      user: {
        website: {
          trial: { is_available: isTrialAvailable },
        },
      },
    },
  } = useGlobalState('user');

  const router = useRouter();
  const { trial } = router.query as {
    trial?: string;
  };

  if (trial === '79') {
    return (
      <TrialBaseModal
        features={trial79}
        heading={t('heading_79')}
        subheading={
          isTrialAvailable
            ? t('trial_description_79')
            : t('paid_plan_description_79')
        }
        webpushImpressions={25000}
        emailImpressions={4000}
        handleClose={() => router.push('/')}
        paidPlanName='Power Bundle'
        amount={79}
        sku={
          isTrialAvailable ? 'power_bundle_custom' : 'power_bundle_reinstall'
        }
        pricingSlabs={[
          'email_impressions_4000_price_0.00',
          'webpush_impressions_25000_price_79.00',
        ]}
        isTrialAllowed={isTrialAvailable}
      />
    );
  }

  if (trial === '19') {
    return (
      <TrialBaseModal
        features={trial19}
        heading={t('plus_bundle_heading')}
        subheading={
          isTrialAvailable
            ? t('trial_description_19')
            : t('paid_plan_description_19')
        }
        webpushImpressions={10000}
        emailImpressions={500}
        amount={19}
        handleClose={() => router.push('/')}
        paidPlanName='Plus Bundle'
        sku={isTrialAvailable ? 'plus_bundle_custom' : 'plus_bundle_reinstall'}
        pricingSlabs={[
          'email_impressions_500_price_0.00',
          'webpush_impressions_10000_price_19.00',
        ]}
        isTrialAllowed={isTrialAvailable}
      />
    );
  }

  if (trial === '38') {
    return (
      <TrialBaseModal
        features={trial38}
        heading={t('plus_bundle_heading')}
        subheading={
          isTrialAvailable
            ? t('trial_description_38')
            : t('paid_plan_description_38')
        }
        webpushImpressions={10000}
        emailImpressions={1500}
        amount={38}
        handleClose={() => router.push('/')}
        paidPlanName='Plus Bundle'
        sku={isTrialAvailable ? 'plus_bundle_custom' : 'plus_bundle_reinstall'}
        pricingSlabs={[
          'email_impressions_1500_price_0.00',
          'webpush_impressions_10000_price_38.00',
        ]}
        isTrialAllowed={isTrialAvailable}
      />
    );
  }

  return null;
};
