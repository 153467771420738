import { arrayMove } from '@dnd-kit/sortable';
import { createModel } from '@rematch/core';
import cloneDeep from 'lodash.clonedeep';
import _set from 'lodash.set';
import unset from 'lodash.unset';
import Router from 'next/router';
import {
  AllTimeTimeFilter,
  TimeFilter,
} from 'src/components/HistoricalDaterangePicker/types';
import { getListsAPI } from 'src/lib/api/brevo';
import {
  createOptinFlowFromTemplateAPI,
  createOptinFlowNodeAPI,
  fetchFlyoutConfigAPI,
  fetchIOSWidgetConfigAPI,
  fetchOptinByIdAPI,
  fetchOptinConfigAPI,
  fetchOptinTemplatesAPI,
  listOptinFlowsAPI,
  patchOptinFlowAPI,
  patchOptinFlowFormWidgetAPI,
  patchOptinFlowNodeAPI,
  setFlyoutConfigAPI,
  setIOSWidgetConfigAPI,
  setOptinConfigAPI,
  sortOptinFlowAPI,
} from 'src/lib/api/optins';
import { ChannelType, FlowNodeType, Status } from 'src/lib/constants';
import { convertFixedToRange } from 'src/lib/date-utils';
import { logErrorToSentry } from 'src/lib/debug-utils';
import {
  getFirstEditableFormWidget,
  getNearestFormCollectionNode,
  groupOptinNodes,
  isPopupPromptFormWidget,
  shouldDisableFirstWaitNode,
  sortFormWidgetsByStepType,
  sortOptinFlowsByStatus,
} from 'src/lib/utils';
import { RootModel } from 'src/store/models';
import { ReservedContainerNodeId } from '../components/FormWidget/lib/constants';
import { TreeNode, TreeNodeType } from '../components/FormWidget/lib/types';
import { getTreeNodeById } from '../util/treeUtils';
import { isValidOptinsState } from '../util/validations';
import {
  FormCollectionNode,
  FormWidget,
  OptinFlow,
  OptinFlowNode,
  OptinStatistic,
  OptinTemplate,
} from './types';

export interface OptinsData {
  active: string;
  oneStep: {
    overlay: {
      enabled: boolean;
      title: string;
      description: string;
    };
    timeout: {
      default: number;
      mobile: number;
    };
    maxCountPerSession: number;
    deferForDays: number;
  };
  twoStep: {
    multilingual_title: { [lang: string]: string };
    multilingual_description: { [lang: string]: string };
    overlay: {
      enabled: boolean;
      title: string;
      description: string;
    };
    timeout: {
      default: number;
      mobile: number;
    };
    yesButton: {
      multilingual_label: { [lang: string]: string };
    };
    noButton: {
      multilingual_label: { [lang: string]: string };
    };
    theme: {
      yesButtonBgColor: string;
      yesButtonColor: string;
      noButtonBgColor: string;
    };
    position: {
      default: string;
      mobile: string;
    };
    maxCountPerSession: number;
    deferForDays: number;
  };
}

export interface FlyOutData {
  button_text: string;
  enabled: boolean;
  post_subscription_message: string;
  title: string;
  position: { default: string; mobile: string };
  theme: { primaryColor: string; secondaryColor: string };
  overlay: { enabled: boolean; title: string; description: string };
}

export interface IOSWidgetData {
  enabled: boolean;
  title: string;
  description: string;
}

interface OptinsState {
  errors: any;
  isChanged: boolean;
  isSaving: boolean;
  isFlowNodeSaving: boolean;
  isOptinSaving: boolean;
  isFormWidgetSaving: boolean;
  unsavedChangesModalOpen: boolean;
  isCreating: boolean;
  isAddingChannel: boolean;
  templates: {
    isFetching: boolean;
    templateList: OptinTemplate[];
  };
  optinFlows: {
    isFetching: boolean;
    optinFlowList: OptinFlow[];
    overallStats: OptinStatistic;
  };
  timeFilter: TimeFilter;
  activeOptinFlow: OptinFlow;
  optin: {
    isFetching: boolean;
    current: OptinFlow;
    edited: OptinsData;
  };
  selectedOptinNodeId: number;
  selectedFormWidgetId: number;
  flyout: {
    isFetching: boolean;
    current: FlyOutData;
    edited: FlyOutData;
  };
  iosWidget: {
    isFetching: boolean;
    current: IOSWidgetData;
    edited: IOSWidgetData;
  };
  lists: {
    isFetching: boolean;
    lists: { id: number; name: string }[];
  };
}

const defaultIOSData = {
  enabled: false,
  title: 'Install app',
  description:
    "Enhance your mobile experience by installing our app. Tap the {{share icon}} icon and select 'Add to Home Screen' for seamless access.",
};

const initialState: () => OptinsState = () => ({
  errors: {
    title: {},
    description: {},
    yesButtonLabel: {},
    noButtonLabel: {},
  },
  isChanged: false,
  unsavedChangesModalOpen: false,
  isSaving: false, // legacy saving boolean
  isCreating: false,
  isFlowNodeSaving: false,
  isOptinSaving: false,
  isFormWidgetSaving: false,
  isAddingChannel: false,
  templates: {
    isFetching: true,
    templateList: [],
  },
  optinFlows: {
    isFetching: true,
    optinFlowList: [],
    overallStats: null,
  },
  timeFilter: AllTimeTimeFilter,
  activeOptinFlow: null,
  optin: {
    isFetching: true,
    current: null,
    edited: null,
  },
  selectedOptinNodeId: null,
  selectedFormWidgetId: null,
  flyout: {
    isFetching: true,
    current: null,
    edited: null,
  },
  iosWidget: {
    isFetching: false,
    current: defaultIOSData,
    edited: defaultIOSData,
  },
  lists: {
    isFetching: false,
    lists: [],
  },
});

const optins = createModel<RootModel>()({
  state: initialState(),

  effects: dispatch => ({
    async fetchOptinById(payload: { id: number }) {
      this.storeConfig({
        isChanged: false,
        optin: {
          isFetching: true,
          current: null,
        },
      });

      dispatch.optins.resetFormWidgetEditor();

      const { data: optin, error }: { data: OptinFlow; error } =
        await fetchOptinByIdAPI(payload.id);

      if (optin && optin.flow_nodes) {
        optin.flow_nodes = optin.flow_nodes.map((node: OptinFlowNode) => {
          if (node.type === FlowNodeType.WAIT_TIME) {
            if (node?.wait_times?.default === undefined) {
              node.wait_times.default = node.wait_time;
            }

            if (node?.wait_times?.mobile === undefined) {
              node.wait_times.mobile = node.wait_time;
            }
          }

          if (node.type === FlowNodeType.FORM_COLLECTION) {
            const sortedForms = sortFormWidgetsByStepType(node.forms);
            return {
              ...node,
              forms: sortedForms,
            };
          }
          return node;
        });
      }

      this.storeConfig({
        optin: {
          isFetching: false,
          current: error ? null : optin,
        },
      });

      if (!error) {
        const firstFormCollectionNodeId = optin.flow_nodes.find(
          node => node.type === FlowNodeType.FORM_COLLECTION,
        )?.id;
        const firstFormWidget = getFirstEditableFormWidget(optin);

        if (firstFormWidget && !Router?.query?.node) {
          Router.replace({
            query: { ...Router.query, node: firstFormCollectionNodeId },
          });

          dispatch.formWidgetEditor.setWorkingFormWidget({
            formWidget: firstFormWidget ? cloneDeep(firstFormWidget) : null,
            defaultNodeToSelect: ReservedContainerNodeId.TEXT_CONTENT,
          });
        }
      }

      if (error) {
        dispatch.saveToast.showError('Error fetching optin');
      }
    },

    async createOptinFlowFromTemplate(payload: { id: number }) {
      this.storeConfig({
        isCreating: true,
      });
      const { data: optin }: { data: OptinFlow; error } =
        await createOptinFlowFromTemplateAPI(payload.id);

      if (optin && optin?.id) {
        await Router.push(`/optins/edit/${optin.id}`);
      } else {
        dispatch.saveToast.showError('Error creating optin');
      }

      this.storeConfig({
        isCreating: false,
      });
    },

    editOptinPropByPath(
      payload: { id: number; path: string; value: any },
      rootState,
    ) {
      const { optinFlows } = rootState.optins;

      const optinIdx = optinFlows.optinFlowList?.findIndex(
        optin => optin.id === payload.id,
      );

      // update name if we are in the editor
      if (rootState.optins.optin.current) {
        _set(rootState.optins.optin.current, payload.path, payload.value);

        this.storeConfig({
          optin: {
            ...rootState.optins.optin,
            current: rootState.optins.optin.current,
          },
        });
      }

      // update name if we are on the listing page
      if (optinIdx !== -1) {
        _set(
          optinFlows,
          `optinFlowList.[${optinIdx}].${payload.path}`,
          payload.value,
        );

        this.storeConfig({
          optinFlows: {
            ...rootState.optins.optinFlows,
            optinFlowList: optinFlows.optinFlowList,
          },
        });
      }
    },

    async renameOptinflow(payload: { id: number; name: string }, rootState) {
      const oldName = rootState.optins.optinFlows?.optinFlowList?.find(
        optin => optin.id === payload.id,
      )?.name;

      // do optimistic update first
      dispatch.optins.editOptinPropByPath({
        id: payload.id,
        path: 'name',
        value: payload.name,
      });

      const { error } = await patchOptinFlowAPI(payload.id, {
        name: payload.name,
      });

      if (!error) {
        dispatch.saveToast.showDone('Optin renamed successfully');
      }

      if (error) {
        dispatch.saveToast.showError('Error renaming optin');
        // undo optimistic update on error
        if (oldName) {
          dispatch.optins.editOptinPropByPath({
            id: payload.id,
            path: 'name',
            value: oldName,
          });
        }
      }
    },

    async changeOptinFlowStatus(payload: {
      id: number | string;
      status: Status;
    }) {
      this.storeConfig({
        isOptinSaving: true,
      });
      const { error } = await patchOptinFlowAPI(payload.id, {
        status: payload.status,
      });

      if (!error) {
        dispatch.saveToast.showDone('Optin status changed');

        this.storeConfig({
          isOptinSaving: false,
        });
      }

      if (error) {
        dispatch.saveToast.showError('Error changing status');
      }
    },

    async fetchOptinFlows(
      payload: { startTime?: number; endTime?: number },
      rootState,
    ) {
      const { timeFilter } = rootState.optins;

      this.storeConfig({
        optinFlows: {
          isFetching: true,
        },
      });

      const { start, end } = convertFixedToRange(timeFilter).value;
      const { data: optins, error } = await listOptinFlowsAPI({
        startTime: payload?.startTime || start.getTime(),
        endTime: payload?.endTime || end.getTime(),
      });

      let isOptinsEmpty = false;
      if (!error && optins?.flows?.length === 0) {
        isOptinsEmpty = true;
      }

      // sort by status so that active optinflow always comes first on the list
      if (!error && !isOptinsEmpty) {
        sortOptinFlowsByStatus(optins.flows);
      }

      this.storeConfig({
        optinFlows: {
          isFetching: false,
          optinFlowList: error || isOptinsEmpty ? [] : optins.flows,
          overallStats: optins.overall_statistics,
        },
      });

      if (error) {
        dispatch.saveToast.showError('Error fetching optins');
      }
    },

    async fetchActiveOptinFlow() {
      const { data } = await listOptinFlowsAPI({
        status: Status.ACTIVE,
      });

      const activeOptinFlow = data?.flows?.length > 0 ? data.flows?.[0] : null;

      this.storeConfig({
        activeOptinFlow,
      });

      return activeOptinFlow;
    },

    setSelectedOptinNode(
      payload: { id: number; shouldSelectLastFormWidget?: boolean },
      rootState,
    ) {
      const optin = rootState.optins.optin.current;

      const selectedNode = optin?.flow_nodes?.find(
        node => node.id === payload.id,
      );

      // If selected node is a form collection, preselect the first form widget
      if (
        selectedNode?.type === FlowNodeType.FORM_COLLECTION &&
        selectedNode?.forms?.length > 0
      ) {
        const formWidgetToSelect = payload.shouldSelectLastFormWidget
          ? selectedNode?.forms?.[selectedNode.forms.length - 1]
          : selectedNode?.forms?.[0];

        this.storeConfig({
          selectedFormWidgetId: formWidgetToSelect?.id,
        });

        dispatch.formWidgetEditor.setWorkingFormWidget({
          formWidget: formWidgetToSelect ? cloneDeep(formWidgetToSelect) : null,
          defaultNodeToSelect: ReservedContainerNodeId.TEXT_CONTENT,
        });
      }

      this.storeConfig({
        selectedOptinNodeId: payload.id,
      });
    },

    skipToNearestEditable(payload: { direction: -1 | 1 }, rootState) {
      const {
        selectedOptinNodeId,
        selectedFormWidgetId,
        optin: { current: optin },
      } = rootState.optins;
      const { setFormWidgetStep } = dispatch.aiEditor;

      const selectedOptinNode = optin?.flow_nodes?.find(
        node => node.id === selectedOptinNodeId,
      );

      if (selectedOptinNode) {
        if (selectedOptinNode?.type === FlowNodeType.FORM_COLLECTION) {
          const selectedFormWidgetIdx = selectedOptinNode.forms.findIndex(
            form => form.id === selectedFormWidgetId,
          );

          const nextFormWidget =
            selectedOptinNode.forms[selectedFormWidgetIdx + payload.direction];

          if (nextFormWidget) {
            dispatch.optins.setSelectedFormWidget({
              id: nextFormWidget.id,
            });
          } else {
            // if current form in the node is the last/first form widget, search for another nearest form collection node to skip to
            const nextFormCollectionNode = getNearestFormCollectionNode({
              optinNodes: optin.flow_nodes,
              currentNodeId: selectedOptinNode.id,
              searchForward: payload.direction > 0,
            });

            if (nextFormCollectionNode) {
              dispatch.optins.setSelectedOptinNode({
                id: nextFormCollectionNode.id,
                shouldSelectLastFormWidget: payload.direction < 0,
              });
              if (payload.direction > 0) {
                setFormWidgetStep(0);
              }
              Router.replace({
                query: { ...Router.query, node: nextFormCollectionNode.id },
              });
            }
          }
        }
      }
    },

    /**
     * making `suppressModal` true will not open the unsaved changes modal, it is false by default
     */
    checkForUnsavedChanges(payload: { suppressModal: boolean }, rootState) {
      const { isChanged } = rootState.optins;

      if (isChanged || rootState.formWidgetEditor.isChanged) {
        if (!payload?.suppressModal) {
          this.storeConfig({
            unsavedChangesModalOpen: true,
          });
        }

        return true;
      }

      return false;
    },

    closeUnsavedChangesModal() {
      this.storeConfig({
        unsavedChangesModalOpen: false,
      });
    },

    async saveUnsavedChanges(_, rootState) {
      if (
        rootState.formWidgetEditor.isChanged &&
        rootState.optins.selectedFormWidgetId
      ) {
        // save the actual form/popup (tree)
        await dispatch.formWidgetEditor.saveFormWidget();
      } else if (rootState.optins.isChanged) {
        await Promise.all([
          // save top level optin optins (frequency, url...)
          dispatch.optins.saveOptinOptions(),
          // save low level form options (mobile and desktop positioning)
          dispatch.optins.saveNodeFormOptions(),
        ]);
      }

      this.closeUnsavedChangesModal();
    },

    resetFormWidgetEditor() {
      this.storeConfig({
        selectedFormWidgetId: null,
        selectedOptinNodeId: null,
      });
      dispatch.formWidgetEditor.resetState();
    },

    setSelectedFormWidget(payload: { id: number }, rootState) {
      const {
        selectedOptinNodeId,
        optin: { current: optin },
      } = rootState.optins;

      const selectedOptinNode = optin?.flow_nodes?.find(
        node => node.id === selectedOptinNodeId,
      );

      if (selectedOptinNode?.type === FlowNodeType.FORM_COLLECTION) {
        this.storeConfig({
          selectedFormWidgetId: payload.id,
        });

        const selectedFormWidget = selectedOptinNode.forms.find(
          form => form.id === payload.id,
        );

        dispatch.formWidgetEditor.setWorkingFormWidget({
          formWidget: selectedFormWidget ? cloneDeep(selectedFormWidget) : null,
          defaultNodeToSelect: ReservedContainerNodeId.TEXT_CONTENT,
        });
      } else {
        this.storeConfig({
          selectedFormWidgetId: null,
        });
        dispatch.formWidgetEditor.setWorkingFormWidget({
          formWidget: null,
        });
      }
    },

    setTimeFilter(payload: { timeFilter: TimeFilter }) {
      this.storeConfig({
        timeFilter: payload.timeFilter,
      });
    },

    setOptinOptions(payload: Partial<OptinFlow>, rootState) {
      const optin = rootState.optins.optin.current;

      this.storeConfig({
        isChanged: true,
        optin: {
          current: {
            ...optin,
            ...payload,
          },
        },
      });
    },

    setOptinFlowNodeOptions(payload: Partial<OptinFlowNode>, rootState) {
      const optin = rootState.optins.optin.current;

      const id = payload.id || rootState.optins.selectedOptinNodeId;

      this.storeConfig({
        optin: {
          current: {
            ...optin,
            flow_nodes: optin.flow_nodes.map(node => {
              if (node.id === id) {
                return {
                  ...node,
                  ...payload,
                };
              }
              return node;
            }),
          },
        },
      });
    },

    setNodeFormOptions(
      payload: Partial<
        Pick<
          FormWidget,
          'desktop_position' | 'mobile_position' | 'status' | 'list_ids'
        >
      > & {
        formWidgetId: number;
      },
      rootState,
    ) {
      const {
        optin: { current: optin },
        selectedOptinNodeId,
      } = rootState.optins;

      const selectedOptinNode = optin?.flow_nodes?.find(
        node => node.id === selectedOptinNodeId,
      );

      if (selectedOptinNode?.type === FlowNodeType.FORM_COLLECTION) {
        const selectedFormWidget = selectedOptinNode.forms.find(
          form => form.id === payload.formWidgetId,
        );

        if (selectedFormWidget) {
          selectedFormWidget.desktop_position =
            payload.desktop_position || selectedFormWidget.desktop_position;
          selectedFormWidget.mobile_position =
            payload.mobile_position || selectedFormWidget.mobile_position;
          selectedFormWidget.status =
            payload.status || selectedFormWidget.status;
          selectedFormWidget.list_ids =
            payload.list_ids || selectedFormWidget.list_ids;

          this.storeConfig({
            isChanged: true,
            optin: {
              current: {
                ...optin,
                flow_nodes: optin.flow_nodes.map(node => {
                  if (node.id === selectedOptinNodeId) {
                    return selectedOptinNode;
                  }
                  return node;
                }),
              },
            },
          });

          dispatch.formWidgetEditor.setFormWidgetProps({
            status: selectedFormWidget.status,
            mobile_position: selectedFormWidget.mobile_position,
            desktop_position: selectedFormWidget.desktop_position,
          });
        }
      }
    },

    async saveNodeFormOptions(payload: { formWidgetId?: number }, rootState) {
      const {
        optin: { current: optin },
        selectedOptinNodeId,
        selectedFormWidgetId,
      } = rootState.optins;

      const selectedOptinNode = optin?.flow_nodes?.find(
        node => node.id === selectedOptinNodeId,
      );

      if (selectedOptinNode?.type === FlowNodeType.FORM_COLLECTION) {
        const formToUpdate = selectedOptinNode.forms.find(
          form => form.id === (payload?.formWidgetId || selectedFormWidgetId),
        );

        this.storeConfig({
          isFormWidgetSaving: true,
        });

        const { error } = await patchOptinFlowFormWidgetAPI({
          flowId: optin.id,
          nodeId: selectedOptinNodeId,
          formId: formToUpdate.id,
          data: {
            // updating only the required keys to minimize potential errors
            desktop_position: formToUpdate.desktop_position,
            mobile_position: formToUpdate.mobile_position,
            status: formToUpdate.status,
            list_ids: formToUpdate.list_ids,
          },
        });

        this.storeConfig({
          isChanged: false,
          isFormWidgetSaving: false,
        });

        if (error) {
          dispatch.saveToast.showError('Error saving optin');
        } else {
          dispatch.saveToast.showDone('Optin saved successfully');
        }
      }
    },

    async saveOptinFlowNodeOptions(payload: Partial<OptinFlowNode>, rootState) {
      const {
        optin: { current: optin },
        selectedOptinNodeId,
      } = rootState.optins;

      dispatch.optins.setOptinFlowNodeOptions({
        id: payload?.id || selectedOptinNodeId,
        ...payload,
      });

      this.storeConfig({
        isFlowNodeSaving: true,
      });

      const payloadWithoutId = Object.keys(payload)
        .filter(key => key !== 'id')
        .reduce((obj, key) => {
          obj[key] = payload[key];
          return obj;
        }, {});

      const { error } = await patchOptinFlowNodeAPI({
        flowId: optin.id,
        nodeId: payload.id || selectedOptinNodeId,
        data: payloadWithoutId,
      });

      this.storeConfig({
        isFlowNodeSaving: false,
      });

      if (error) {
        dispatch.saveToast.showError('Error saving optin');
      }
    },

    async sortNode(
      payload: { active: { id: number }; over: { id: number } },
      rootState,
    ) {
      const {
        optin: { current: optin },
      } = rootState.optins;

      const { active, over } = payload;
      const originalFlowNodes = optin?.flow_nodes || [];

      const groupedNodes = groupOptinNodes(originalFlowNodes);
      const oldIndex = groupedNodes.findIndex(group => group.id === active.id);
      const newIndex = groupedNodes.findIndex(group => group.id === over.id);

      const newFlowNodes = arrayMove(groupedNodes, oldIndex, newIndex)
        .reduce((acc, group) => {
          return [...acc, group.waitTime, group.form];
        }, [])
        .map((node, index) => ({ ...node, sort_key: index + 1 }))
        .map((node, index) => {
          if (
            index === 0 &&
            node.type === FlowNodeType.WAIT_TIME &&
            shouldDisableFirstWaitNode(optin)
          ) {
            return {
              ...node,
              wait_times: {
                ...node.wait_times,
                default: 0,
                mobile: 0,
              },
            };
          }

          return node;
        });

      this.storeConfig({
        optin: {
          current: {
            ...optin,
            flow_nodes: newFlowNodes,
          },
        },
      });

      const { error } = await sortOptinFlowAPI(
        optin.id,
        newFlowNodes.map(node => node.id),
      );

      if (error) {
        this.storeConfig({
          optin: {
            current: {
              ...optin,
              flow_nodes: originalFlowNodes,
            },
          },
        });

        dispatch.saveToast.showError('Error reordering optin');
      }
    },

    async saveOptinOptions(_, rootState) {
      const optin = rootState.optins.optin.current;

      this.storeConfig({
        isOptinSaving: true,
      });

      const { error } = await patchOptinFlowAPI(optin.id, {
        // add other fields if needed
        frequency: optin.frequency,
        defer_for_days: optin.defer_for_days,
        image_url: optin.image_url,
        exclusion_urls: optin.exclusion_urls,
        inclusion_urls: optin.inclusion_urls,
        inclusion_urls_enabled: optin.inclusion_urls_enabled,
        exclusion_urls_enabled: optin.exclusion_urls_enabled,
        scroll_depth: optin.scroll_depth,
        scroll_depth_enabled: optin.scroll_depth_enabled,
        exit_intent: optin.exit_intent,
        time_spent: optin.time_spent,
        time_spent_enabled: optin.time_spent_enabled,
      });
      this.storeConfig({
        isOptinSaving: false,
        isChanged: false,
      });

      if (error) {
        dispatch.saveToast.showError('Error saving optin');
      }
    },

    /**
     * syncs the main (optin) redux state into the working tree (editor) redux state
     * mainly used for discarding/undoing changes
     */
    syncCurrentTreeOntoWorkingTree(_, rootState) {
      const {
        optin: { current: optin },
        selectedOptinNodeId,
        selectedFormWidgetId,
      } = rootState.optins;

      const selectedOptinNode = optin?.flow_nodes?.find(
        node => node.id === selectedOptinNodeId,
      );

      if (selectedOptinNode?.type === FlowNodeType.FORM_COLLECTION) {
        const selectedFormWidget = selectedOptinNode.forms.find(
          form => form.id === selectedFormWidgetId,
        );

        if (selectedFormWidget) {
          dispatch.formWidgetEditor.setWorkingFormWidget({
            formWidget: cloneDeep(selectedFormWidget),
          });
        }
      }
    },

    /**
     * syncs the working tree (editor) redux state into the main (optin) redux state
     * mainly used for saving the form widget
     * @param payload working tree that the user is currently editing
     */
    syncWorkingTreeOntoCurrentTree(
      payload: {
        workingTree: TreeNode | OptinsData;
        optinNodeId?: number;
        formWidgetId?: number;
      },
      rootState,
    ) {
      const {
        optin: { current: optin },
        selectedOptinNodeId,
        selectedFormWidgetId,
      } = rootState.optins;

      const optinNodeId = payload?.optinNodeId || selectedOptinNodeId;
      const formWidgetId = payload?.formWidgetId || selectedFormWidgetId;

      const selectedOptinNode = optin?.flow_nodes?.find(
        node => node.id === optinNodeId,
      );

      if (selectedOptinNode?.type === FlowNodeType.FORM_COLLECTION) {
        const selectedFormWidget = selectedOptinNode.forms.find(
          form => form.id === formWidgetId,
        );

        if (selectedFormWidget) {
          selectedFormWidget.config = { ...payload.workingTree };

          this.storeConfig({
            optin: {
              current: {
                ...optin,
                flow_nodes: optin.flow_nodes.map(node => {
                  if (node.id === optinNodeId) {
                    return selectedOptinNode;
                  }
                  return node;
                }),
              },
            },
          });
        }
      }
    },

    async saveFormWidgetTree(
      payload: {
        workingTree: TreeNode | OptinsData;
        silent?: boolean;
        optinNodeId?: number;
        formWidgetId?: number;
        disableThumbnailRefresh?: boolean;
      },
      rootState,
    ) {
      const {
        optin: { current: optin },
        selectedOptinNodeId,
        selectedFormWidgetId,
      } = rootState.optins;

      const optinNodeId = payload?.optinNodeId || selectedOptinNodeId;
      const formWidgetId = payload?.formWidgetId || selectedFormWidgetId;

      if (!payload.silent) {
        this.storeConfig({
          isFormWidgetSaving: true,
        });
      }

      const { error, data: editedFormWidget }: { data: FormWidget; error } =
        await patchOptinFlowFormWidgetAPI({
          flowId: optin.id,
          nodeId: optinNodeId,
          formId: formWidgetId,
          data: { config: payload.workingTree },
        });

      let editedImageNode: TreeNode;
      let oldImageNode: TreeNode;
      if (editedFormWidget) {
        const firstFormWidget = getFirstEditableFormWidget(optin);
        if (
          isPopupPromptFormWidget(firstFormWidget) &&
          isPopupPromptFormWidget(editedFormWidget)
        ) {
          editedImageNode = getTreeNodeById(
            editedFormWidget.config,
            ReservedContainerNodeId.IMAGE,
          );
          oldImageNode = getTreeNodeById(
            firstFormWidget.config,
            ReservedContainerNodeId.IMAGE,
          );
        }

        dispatch.optins.syncWorkingTreeOntoCurrentTree({
          workingTree: payload.workingTree,
          optinNodeId,
          formWidgetId,
        });
      }

      this.storeConfig({
        isFormWidgetSaving: false,
        isChanged: false,
      });

      // do the image url change after all the state changes related to save optin are done (keeping this fault tolerant)
      if (
        editedImageNode?.type === TreeNodeType.BOX &&
        oldImageNode?.type === TreeNodeType.BOX &&
        editedImageNode?.attr?.bgUrl !== oldImageNode?.attr?.bgUrl &&
        !payload?.disableThumbnailRefresh
      ) {
        dispatch.optins.setOptinOptions({
          image_url: editedImageNode?.attr?.bgUrl,
        });
        dispatch.optins.saveOptinOptions();
      }

      if (error) {
        dispatch.saveToast.showError('Error saving optin');
      } else if (!payload?.silent) {
        dispatch.saveToast.showDone('Optin saved successfully');
      }
    },

    async fetchTemplates(payload: {
      channels?: string[];
    }): Promise<OptinTemplate[]> {
      this.storeConfig({
        templates: {
          isFetching: true,
          templateList: [],
        },
      });

      const { data: templates, error }: { data: OptinTemplate[]; error } =
        await fetchOptinTemplatesAPI(payload.channels);

      // group templates by channels
      if (templates?.length) {
        templates.sort((t1, t2) => {
          const channel1 = t1.channels.join('');
          const channel2 = t2.channels.join('');

          if (channel1 < channel2) {
            return 1;
          }
          if (channel1 > channel2) {
            return -1;
          }
          return 0;
        });
      }

      this.storeConfig({
        templates: {
          isFetching: false,
          templateList: error ? [] : templates,
        },
      });

      if (error) {
        dispatch.saveToast.showError('Error fetching templates');
      }

      return templates || [];
    },

    async fetchOptins() {
      await fetchOptinConfigAPI().then(res => {
        if (!res.error) {
          this.storeConfig({
            optin: {
              current: res.data,
              // Objects is cloned so that current and edited refers to different objects
              edited: cloneDeep(res.data),
              isFetching: false,
            },
          });
        }
      });
    },

    async fetchFlyout() {
      await fetchFlyoutConfigAPI().then(res => {
        if (!res.error) {
          this.storeConfig({
            flyout: {
              current: res.data.flyout,
              // Objects is cloned so that current and edited refers to different objects
              edited: cloneDeep(res.data.flyout),
              isFetching: false,
            },
          });
        }
      });
    },

    async fetchIOSWidget() {
      const res = await fetchIOSWidgetConfigAPI();

      if (!res.error) {
        this.storeConfig({
          iosWidget: {
            current: res.data,
            edited: cloneDeep(res.data),
            isFetching: false,
          },
        });
      }
    },

    removeOptinsProp(payload: { path: string | Array<string> }, rootState) {
      const optinsState = cloneDeep(rootState.optins);

      if (Array.isArray(payload.path)) {
        payload.path.forEach(pathToDelete => {
          unset(optinsState, pathToDelete);
        });
      } else {
        unset(optinsState, payload.path);
      }

      this.storeConfig(optinsState);

      this.storeConfig({
        isChanged: true,
      });
    },

    setOptinsProp(payload: { path: string; value: any }, rootState) {
      const optinsState = rootState.optins;

      _set(optinsState, payload.path, payload.value);

      this.storeConfig(optinsState);

      // Validate
      const { errors } = isValidOptinsState(optinsState.optin.edited);

      this.storeConfig({
        isChanged: true,
        errors,
      });
    },

    setFlyoutProp(payload: { path: string; value: any }, rootState) {
      const optinsState = rootState.optins;

      _set(optinsState, payload.path, payload.value);

      this.storeConfig(optinsState);
      this.storeConfig({ isChanged: true });
    },

    setIOSWidgetProp(payload: { path: string; value: any }, rootState) {
      const optinsState = rootState.optins;

      _set(optinsState, payload.path, payload.value);

      this.storeConfig(optinsState);
      this.storeConfig({ isChanged: true });
    },

    cancelChanges(payload, rootState) {
      const optinsState = rootState.optins;

      this.storeConfig({
        optin: {
          current: optinsState.optin.current,
          edited: cloneDeep(optinsState.optin.current),
        },
        flyout: {
          current: optinsState.flyout.current,
          edited: cloneDeep(optinsState.flyout.current),
        },
        iosWidget: {
          current: optinsState.iosWidget.current,
          edited: cloneDeep(optinsState.iosWidget.current),
        },
      });

      this.storeConfig({
        isChanged: false,
        isSaving: false,
        errors: {
          title: {},
          description: {},
          yesButtonLabel: {},
          noButtonLabel: {},
        },
      });
    },

    async saveChanges(payload: AnyObject = {}, rootState) {
      const optinsState = rootState.optins;
      const optinPayload = payload.optin || {};
      const flyoutPayload = payload.flyout || {};
      const iosPayload = payload.ios || {};

      try {
        const newOptin = { ...optinsState.optin.edited, ...optinPayload };
        const newFlyout = { ...optinsState.flyout.edited, ...flyoutPayload };
        const newiOS = { ...optinsState.iosWidget.edited, ...iosPayload };

        this.storeConfig({
          optin: {
            current: newOptin,
            edited: cloneDeep(newOptin),
          },
          flyout: {
            current: newFlyout,
            edited: cloneDeep(newFlyout),
          },
          iosWidget: {
            current: newiOS,
            edited: cloneDeep(newiOS),
          },
        });
        this.storeConfig({
          isSaving: true,
          errors: {
            title: {},
            description: {},
            yesButtonLabel: {},
            noButtonLabel: {},
          },
        });
        await Promise.all([
          setOptinConfigAPI(newOptin),
          setFlyoutConfigAPI({ flyout: newFlyout }),
          setIOSWidgetConfigAPI(newiOS),
        ]);

        this.storeConfig({ isChanged: false, isSaving: false });
        dispatch.saveToast.showDone('Changes saved');
        Router.push('/optins');
      } catch (error) {
        this.storeConfig({ isChanged: false, isSaving: false });
        dispatch.saveToast.showError('Error saving changes');
      }
    },

    async fetchLists(_, rootState) {
      this.storeConfig({
        lists: {
          ...rootState.optins.lists,
          isFetching: true,
        },
      });

      const { data, error } = await getListsAPI({
        limit: 50,
        offset: 0,
      });

      if (!error) {
        this.storeConfig({
          lists: {
            lists: data?.lists || [],
            isFetching: false,
          },
        });
      } else {
        this.storeConfig({
          lists: {
            ...rootState.optins.lists,
            isFetching: false,
          },
        });
      }
    },

    async deleteNodes(ids: number[], rootState) {
      const {
        optin: { current: optin },
      } = rootState.optins;

      await Promise.all(
        ids.map(id =>
          dispatch.optins.saveOptinFlowNodeOptions({
            id,
            is_archived: true,
          }),
        ),
      );

      optin.flow_nodes = optin.flow_nodes.filter(
        node => !ids.includes(node.id),
      );

      const editableFlowNodes = optin.flow_nodes.filter(
        node => node.type === FlowNodeType.FORM_COLLECTION,
      );

      optin.channels = editableFlowNodes.map(node => node.channels).flat();

      optin.channels = Array.from(new Set(optin.channels));

      const node = editableFlowNodes[0];
      dispatch.optins.setSelectedOptinNode({
        id: node.id,
      });
      Router.replace({
        query: { ...Router.query, node: node.id },
      });

      this.storeConfig({
        optin: {
          current: optin,
          edited: cloneDeep(optin),
        },
      });
    },

    async addChannelNode(payload: { channel: ChannelType }, rootState) {
      const { channel } = payload;

      const {
        optin: { current: optin },
      } = rootState.optins;

      this.storeConfig({
        isAddingChannel: true,
      });

      await dispatch.optins.saveUnsavedChanges();
      const templates = await dispatch.optins.fetchTemplates({
        channels: [channel],
      });

      if (templates.length) {
        // get the first template that matches the channel, that is not a custom HTML optin template
        const channelTemplate = templates.find(
          template =>
            template.channels.length === 1 &&
            template.channels.includes(channel) &&
            !template.data?.flow_nodes?.some(
              (node: FormCollectionNode) =>
                node.forms?.some(
                  (form: any) => form.config.type === TreeNodeType.HTML,
                ),
            ),
        );

        if (channelTemplate) {
          const waitNode = channelTemplate.data?.flow_nodes?.find(
            node => node.type === FlowNodeType.WAIT_TIME,
          );
          const formNode = channelTemplate.data?.flow_nodes?.find(
            node => node.type === FlowNodeType.FORM_COLLECTION,
          );

          const currentMaxSortKey = optin.flow_nodes.reduce(
            (max, node) => Math.max(max, node.sort_key || 0),
            0,
          );

          if (waitNode && formNode) {
            await Promise.all([
              createOptinFlowNodeAPI(optin.id, {
                type: FlowNodeType.WAIT_TIME,
                wait_times: waitNode.wait_times,
                sort_key: currentMaxSortKey + 1,
                wait_time: waitNode.wait_time,
              }),
              createOptinFlowNodeAPI(optin.id, {
                type: FlowNodeType.FORM_COLLECTION,
                forms: formNode.forms,
                sort_key: currentMaxSortKey + 2,
                channels: formNode.channels,
                optin_type: formNode.optin_type,
              }),
            ]).then(() => {
              dispatch.optins.fetchOptinById({ id: optin.id });
            });
          } else {
            dispatch.saveToast.showError('error_creating_channel');
            logErrorToSentry({
              error: 'nodes or channel not found while adding channel',
              extras: {
                channel,
                waitNode,
                formNode,
              },
            });
          }
        } else {
          dispatch.saveToast.showError('error_creating_channel');
          logErrorToSentry({
            error: 'channel template not found while adding channel',
            extras: {
              channel,
            },
          });
        }
      }

      this.storeConfig({
        isAddingChannel: false,
      });
    },
  }),

  reducers: {
    storeConfig(state: OptinsState, payload: OptinsState): OptinsState {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default optins;
