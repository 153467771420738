import { createModel } from '@rematch/core';
import getT from 'next-translate/getT';
import Router from 'next/router';
import { AIModelErrors, getPredefinedPrompts } from 'src/constants/codeEditor';
import { talkToAIAPI } from 'src/lib/api/ai-custom-optin';
import { getBrandDetails } from 'src/lib/api/integrated-onboarding';
import { ChannelType } from 'src/lib/constants';
import { logErrorToSentry } from 'src/lib/debug-utils';
import { getStorage } from 'src/lib/storage';
import { groupOptinNodes, isPopupPromptFormWidget } from 'src/lib/utils';
import {
  TreeNode,
  TreeNodeType,
} from 'src/modules/optins/components/FormWidget/lib/types';
import { OptinsData } from 'src/modules/optins/models';
import { FormWidget, UserPrompt } from 'src/modules/optins/models/types';
import { RootModel } from 'src/store/models';

export enum FormWidgetStep {
  Step1 = 0,
  Step2 = 1,
}

interface InitialState {
  isLoading: boolean;
  userPrompt: UserPrompt[];
  chatError: boolean;
  textfieldValue: string;
  isRegen: boolean;
  formWidgetStep: FormWidgetStep;
}

const defaultState: InitialState = {
  isLoading: false,
  userPrompt: [],
  chatError: false,
  textfieldValue: '',
  isRegen: false,
  formWidgetStep: FormWidgetStep.Step1,
};

const initialState: () => InitialState = () => ({ ...defaultState });

const getBrandColors = async (): Promise<string[]> => {
  try {
    const { data } = await getBrandDetails();
    const primaryColors = data?.primary_color ?? [];
    const secondaryColors = data?.secondary_color ?? [];
    const colors = [...primaryColors, ...secondaryColors];
    if (!colors.length) return [];
    return colors;
  } catch {
    return [];
  }
};

const aiEditor = createModel<RootModel>()({
  state: initialState(),
  effects: dispatch => ({
    async talkToAI(
      payload: {
        prompt: UserPrompt;
        code: string;
        temperature?: number;
      },
      rootState,
    ) {
      const storage = getStorage();
      const { prompt, code, temperature } = payload;
      const { user } = rootState;

      const t = await getT(Router.locale, 'optins');

      this.setIsLoading(true);
      this.setTextfieldValue('');
      storage.set(`${user?.user?.website?.subdomain}-ai-used`, true);
      try {
        if (prompt.prompt === 'edit_brand') {
          const colors = await getBrandColors();
          const promptWithColors = { ...prompt };
          if (colors.length) {
            promptWithColors.prompt = `${prompt.prompt}, colors: ${colors.join(
              ', ',
            )}`;
            const data = await talkToAIAPI({
              prompt: promptWithColors,
              code,
              temperature,
            });
            this.setChatError(false);
            return {
              prompt: data.html as string,
              comments: data.commentFromMe as string,
            };
          }
          this.setChatError(true);
          return {
            prompt: AIModelErrors(
              t('optin_ai_enhancements.errors.no_brand_details'),
            ),
            comments: '',
          };
        }

        const data = await talkToAIAPI({
          prompt: { ...prompt },
          code,
          temperature,
        });
        this.setChatError(false);
        return {
          prompt: data.html as string,
          comments: data.commentFromMe as string,
        };
      } catch (e) {
        this.setChatError(true);
        logErrorToSentry({ extras: { error: 'Error in talkToAI:' }, error: e });
        return {
          prompt: AIModelErrors(t('optin_ai_enhancements.errors.no_response')),
          comments: '',
        };
      } finally {
        this.setIsLoading(false);
      }
    },

    async handleRegenerate(payload: { prompt: string }, state) {
      this.setRegen(true);
      const { userPrompt } = state.aiEditor;
      const lastUserPrompt = [...userPrompt]
        .filter(p => p.role === 'user')
        .pop();
      const res: any = await dispatch.aiEditor.talkToAI({
        prompt: lastUserPrompt,
        code: payload.prompt,
        temperature: parseFloat(Math.random().toFixed(2)),
      });
      const allPromptsCopy = [...userPrompt];
      allPromptsCopy[allPromptsCopy.length - 1] = {
        prompt: res.prompt,
        role: 'bot',
        comments: res.comments,
      };

      dispatch.aiEditor.setUserPrompt({ prompts: allPromptsCopy });
      this.setRegen(false);
    },

    applyToOptin(payload: { code: string; step: FormWidgetStep }) {
      const {
        setIsAIModalOpen,
        setCodeOrPreviewTab: setSelectedTabCodeEditor,
        setNodeContent,
        saveFormWidget,
      } = dispatch.formWidgetEditor;
      setNodeContent({
        nodeId: 'root',
        updateWith(node) {
          if (Array.isArray(node.children)) {
            node.children[payload.step] = payload.code;
          }
        },
      });
      dispatch.aiEditor.resetter(payload.step);
      setIsAIModalOpen({ state: false });
      setSelectedTabCodeEditor({ tab: 'preview' });
      saveFormWidget({ silent: true });
    },

    async handlePredefinedPrompts(
      payload: {
        id: ReturnType<typeof getPredefinedPrompts>[number]['id'];
        step: FormWidgetStep;
      },
      rootState,
    ) {
      const {
        formWidgetEditor: { workingFormWidget },
        user: { user },
      } = rootState;
      const { id } = payload;

      if (
        isPopupPromptFormWidget(workingFormWidget) &&
        TreeNodeType.HTML === workingFormWidget.config.type
      ) {
        const predefinedPrompt = getPredefinedPrompts().find(p => p.id === id);
        if (!predefinedPrompt) return;

        const newPrompt = {
          prompt: predefinedPrompt.prompt,
          role: 'user' as const,
          imageURL:
            id === 'add_logo'
              ? (user?.website?.company_logo as string) ?? ''
              : '',
        };

        const allPrompts = [newPrompt];
        dispatch.aiEditor.setUserPrompt({ prompts: allPrompts });
        const res = await dispatch.aiEditor.talkToAI({
          prompt: newPrompt,
          code: workingFormWidget.config?.children?.length
            ? workingFormWidget.config.children[payload.step]
            : '',
        });
        dispatch.aiEditor.setUserPrompt({
          prompts: [
            ...allPrompts,
            { prompt: res.prompt, role: 'bot', comments: res.comments },
          ],
        });
      }
    },
    async handleUserPromptAndCallAPI(
      payload: { step: FormWidgetStep },
      rootState,
    ) {
      const {
        formWidgetEditor: { workingFormWidget },
        aiEditor: { userPrompt, textfieldValue, chatError },
      } = rootState;
      // Early return if conditions aren't met
      if (
        !isPopupPromptFormWidget(workingFormWidget) ||
        workingFormWidget.config.type !== TreeNodeType.HTML
      ) {
        return;
      }

      // Helper function to get last prompt of specified role
      const getLastPrompt = (prompts: UserPrompt[], role: 'user' | 'bot') =>
        [...prompts].reverse().find(p => p.role === role);

      const allPrompts = [...userPrompt];
      const lastPrompt = allPrompts[allPrompts.length - 1];
      const lastBotResponse = getLastPrompt(allPrompts, 'bot');
      const lastUserPrompt = getLastPrompt(allPrompts, 'user');

      // Prepare new prompt
      const newPrompt: UserPrompt = {
        prompt: textfieldValue,
        role: 'user',
        ...(lastUserPrompt?.imageURL && { imageURL: lastUserPrompt.imageURL }),
      };

      // Update or add the new prompt
      if (lastPrompt?.imageURL && !lastPrompt.prompt) {
        allPrompts[allPrompts.length - 1] = {
          ...lastPrompt,
          ...newPrompt,
        };
      } else {
        allPrompts.push(newPrompt);
      }

      // Update UI with new prompts
      dispatch.aiEditor.setUserPrompt({ prompts: allPrompts });

      const codeForAPI = () => {
        if (!chatError && lastBotResponse) {
          return lastBotResponse.prompt;
        }
        if (workingFormWidget.config?.children?.length) {
          return workingFormWidget.config.children[payload.step] as string;
        }
        return '';
      };

      // Call API and handle response
      const res: any = await dispatch.aiEditor.talkToAI({
        prompt: allPrompts[allPrompts.length - 1],
        code: codeForAPI(),
      });

      // Add bot response and update UI
      allPrompts.push({
        prompt: res.prompt,
        role: 'bot',
        comments: res.comments,
      });

      dispatch.aiEditor.setUserPrompt({ prompts: allPrompts });
    },

    findOtherChannelNode(
      payload: { unSelectedOptinChannel: ChannelType },
      rootState,
    ) {
      const { unSelectedOptinChannel } = payload;
      const {
        optin: { current },
      } = rootState.optins;

      const flowNodes = current?.flow_nodes || [];
      const groupedNodes = groupOptinNodes(flowNodes);
      return groupedNodes.find(
        node => node?.form?.channels?.includes(unSelectedOptinChannel),
      );
    },

    isOtherChannelChildrenPresent(payload: {
      unSelectedOptinChannel: ChannelType;
      step: FormWidgetStep;
    }) {
      const formWidget = (
        this.findOtherChannelNode({
          unSelectedOptinChannel: payload.unSelectedOptinChannel,
        }) as any
      )?.form?.forms[0];
      if (
        isPopupPromptFormWidget(formWidget) &&
        formWidget.config.type === TreeNodeType.HTML &&
        formWidget.config?.children?.length
      ) {
        return formWidget.config?.children[payload.step];
      }
      return false;
    },

    async applyReferenceOptin(
      payload: {
        prompt: ReturnType<typeof getPredefinedPrompts>[number];
        unSelectedOptinChannel: ChannelType;
        step: FormWidgetStep;
      },
      rootState,
    ) {
      const { prompt } = payload;
      const { workingFormWidget } = rootState.formWidgetEditor;
      const { setIsAIModalOpen, setCodeOrPreviewTab } =
        dispatch.formWidgetEditor;
      dispatch.aiEditor.setIsLoading(true);
      try {
        dispatch.aiEditor.setUserPrompt({
          prompts: [{ prompt: prompt.prompt, role: 'user' }],
        });
        const code =
          dispatch.aiEditor.isOtherChannelChildrenPresent({
            unSelectedOptinChannel: payload.unSelectedOptinChannel,
            step: payload.step,
          }) || '';

        const newCode = await dispatch.aiEditor.generateNewCode({
          code,
          prompt: prompt.promptForAI,
        });
        dispatch.aiEditor.updateFormWidget({
          content: newCode,
          formWidget: workingFormWidget,
          step: payload.step,
        });
        dispatch.aiEditor.resetter(payload.step);
        await dispatch.aiEditor.saveFormWidgetSilently();
        setCodeOrPreviewTab({ tab: 'preview' });
        setIsAIModalOpen({ state: false });
        dispatch.aiEditor.setIsLoading(false);
      } catch (error) {
        dispatch.aiEditor.setIsLoading(false);
        logErrorToSentry({ error: 'Error in applyToAll', extras: error });
      }
    },

    updateFormWidget(payload: {
      content: string;
      formWidget: FormWidget<TreeNode | OptinsData>;
      step: FormWidgetStep;
    }) {
      const { content, formWidget } = payload;
      const { setNodeContent } = dispatch.formWidgetEditor;
      setNodeContent({
        nodeId: 'root',
        updateWith(node) {
          if (Array.isArray(node.children)) {
            node.children[payload.step] = content;
          }
        },
        formWidget,
      });
    },

    saveFormWidgetSilently(_, rootState) {
      const { workingFormWidget } = rootState.formWidgetEditor;
      const { saveFormWidget } = dispatch.formWidgetEditor;
      return saveFormWidget({
        silent: true,
        formWidgetId: workingFormWidget.id,
        workingTree: workingFormWidget.config,
        disableThumbnailRefresh: true,
      });
    },

    async generateNewCode(payload: { code: string; prompt: string }) {
      const { code, prompt } = payload;
      try {
        const res = await this.talkToAI({
          prompt: {
            prompt,
            role: 'user',
          },
          code,
        });

        return (res as any).prompt;
      } catch (error) {
        logErrorToSentry({
          error,
          extras: {
            comment: 'Error generating code to apply to the remaining channel',
          },
        });
        return '';
      }
    },
  }),
  reducers: {
    setIsLoading(state, isLoading: boolean) {
      return {
        ...state,
        isLoading,
      };
    },
    setUserPrompt(state, { prompts }: { prompts: UserPrompt[] }) {
      return {
        ...state,
        userPrompt: prompts,
      };
    },
    setChatError(state, chatError: boolean) {
      return {
        ...state,
        chatError,
      };
    },
    setTextfieldValue(state, textfieldValue: string) {
      return {
        ...state,
        textfieldValue,
      };
    },
    setRegen(state, isRegen: boolean) {
      return {
        ...state,
        isRegen,
      };
    },
    setFormWidgetStep(state, formWidgetStep: FormWidgetStep) {
      return {
        ...state,
        formWidgetStep,
      };
    },
    resetter(state, currentStep: FormWidgetStep) {
      return { ...state, ...defaultState, formWidgetStep: currentStep };
    },
  },
});

export default aiEditor;
